import React from 'react'
import Layout from '../components/layout'
import Banner from '../components/partials/banner'
import { css } from '@emotion/core'
import useScript from '../hooks/useScript';

  
const FulfillmentPage = () => {
  
  return <Layout title="Fulfillment Policy">
    <Banner />

    <div css={css`
      padding: 5rem 3rem;
      display: grid;
      grid-template-columns: minmax(1.2rem, 1fr) minmax(auto, 57ch) minmax(1.2rem, 1fr);
      justify-content: center;
      justify-items: center;
    `}>
      <div css={css`grid-column: 2;`}>
        <h1>Fulfillment Policy</h1>

        <p>Enrollments in Mastery Games are given unlimited access to the course materials, to progress at one's own pace.</p>

        <p>Students are given lifetime access provided the business is in operation.</p>

        <h2>Returns</h2>
        <p>For paid enrollments, students can request and receive a full refund withing 30 days if they are unsatisfied for any reason.</p>

        <h2>Contact</h2>
        <p>Feel free to <a href="mailto:dave@mastery.games">reach out</a> if you have any questions.</p> <br/>
      </div>
    </div>

  </Layout>
}

export default FulfillmentPage
